<template>
    <div class="page">
        <div class="conter w-1350">
            <div class="conter-left">
                <div class="hzycenter-text">
                    <p></p>
                    <span>推荐电商平台</span>
                </div> 
                <div class="nav-list">
                    <div class="nav-item" v-for="(item,index) in pingtaiList" :key="index"  @click="tabClick(item.id)">
                        <div  :class="{active:id==item.id}">{{item.name}}</div>
                        <p><i class="el-icon-arrow-right"></i></p>                         
                    </div>
                </div>
            </div>

            <div class="conter-right">
                <div class="hzynav">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/kjdspt?hzy_id=3'}">外贸营销平台工具</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{path: '/kjdspt?hzy_id=3'}">推荐电商平台</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="details-wrap">
                    <div class="details-text">{{detailsList.name}}</div>
                    <div class="details-p" v-html="detailsList.content"></div>
                    <div class="button" @click="goUrl(detailsList.link)">
                        <img src="../../../assets/images/button.png" alt="">
                        <p>点此进入平台</p>
                    </div>

                    
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
    import {getplatexts,getdetails} from '../../../api/index';
    export default {
        data() {
            return {
                id:'',
                coun_id:'',
                pingtaiList:[],
                detailsList:[]
            }
        },
        created() {
            this.id=this.$route.query.id
            this.coun_id=this.$route.query.coun_id
            console.log(this.$route.query.id)
            this.getplatexts();
            this.getdetails();
            
        },
        methods: {    
            // 跳转外链
            goUrl(url){
               window.open(url) 
            },
            tabClick(id){
                this.id = id;
                this.getdetails();
            },
            //获取电商平台列表
            getplatexts(){
                getplatexts({
                    plate_id:3,
                    coun_id:this.coun_id
                }).then((res)=>{
                    console.log(res);
                    if (res.code==200) {
                        this.pingtaiList=res.data.data;
                    }
                })
            },
            //获取内容
            getdetails(){
                getdetails({
                    id:this.id
                }).then((res)=>{
                    // console.log(res);
                    if (res.code==200) {
                        this.detailsList=res.data;
                    }
                })
            },       
        },
    }
</script>

<style lang="less" scoped>
    .page{background: #F4F4F4;overflow: hidden;}
    .hzynav{height:25px;display: flex;align-items: center;}
    .hzycenter-text{
        height:25px;display: flex;align-items: center;
        p{width: 3px;height: 25px;background: #0071DB;}
        span{font-size: 18px;color: #0071DB;margin-left: 10px;}
    }
    .conter{
        margin: auto;margin-bottom:140px;margin-top: 45px;display: flex;
        .conter-left{
            width:230px;flex-shrink: 0;margin-right: 15px;
            max-height: 70vh;
                overflow: auto;
                &::-webkit-scrollbar {
                width:5px;
                }
                /* 滚动槽 */
                &::-webkit-scrollbar-track {
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
                border-radius:10px;
                }
                /* 滚动条滑块 */
                &::-webkit-scrollbar-thumb {
                border-radius:10px;
                background:rgba(0,0,0,0.1);
                -webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
                }
            .nav-list{
                border: 1px solid #CDCDCD;margin-top:15px;
                .nav-item{cursor: pointer;
                    height: 50px;padding: 0 20px;display: flex;align-items: center;justify-content: space-between;border-bottom: 1px solid #CDCDCD;
                    div{font-size: 16px;color: #3D3D3D;}
                    p{color: #0071DB;font-size: 22px;font-weight: bold;}
                    &:hover{
                        div{color: #0071DB;}
                    }
                    &:last-of-type{border-bottom: none;}
                }
                .active{color: #0071DB !important;}

            }
        }
        .conter-right{
            flex: 1;
            .details-wrap{
                background: #fff;margin-top: 15px;padding: 50px 0;
                .details-text{color: #555555;font-size: 28px;margin-bottom: 40px;text-align: center;}
                .details-p{color: #666666;font-size: 16px;line-height: 32px; padding: 0 50px;}   
                .button{
                    width: 151px;height: 43px;position: relative;margin:80px auto;cursor: pointer;
                    img{width: 100%;height: 100%;}
                    p{width: 151px;height: 43px;position: absolute;top: 0;left: 0;color: #0059AC;font-size: 18px;line-height: 43px;text-align: center;}
                } 
            }
        }
        
    }

</style>